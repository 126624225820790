import { Outlet, createFileRoute } from "@tanstack/react-router";
import { TreeView } from "@/components/ui/tree-view";
import { useQuery } from "@tanstack/react-query";
import { getLearningUnits } from "@/lib/api";
import { Spinner } from "@/components/ui/spinner";
import ErrorHandler from "@/components/error-handler";
import { unique } from "@/lib/utils";

export const Route = createFileRoute("/_auth/operations/units")({
  component: () => <OperationsPage />,
});

function OperationsPage() {
  const { data, error, isLoading } = useQuery({
    queryKey: ["get-units"],
    queryFn: () => getLearningUnits(),
  });

  const navigate = Route.useNavigate();

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  const edukitaLevels = data
    ? unique(data.map((unit) => unit!.edukitaLevel!.name))
    : [];

  const treeData = edukitaLevels.map((level) => {
    const units =
      (data || []).filter((unit) => unit?.edukitaLevel?.name === level) || [];
    return {
      id: level,
      name: level,
      children: units
        .filter((unit) => unit.edukitaLevel?.name === level)
        .map((unit) => {
          return {
            id: unit.id.toString(),
            name: unit.name,
            children: unit.subunits.map((subunit) => {
              return {
                id: subunit.id.toString(),
                name: subunit.name,
                children: subunit.worksheets.map((worksheet) => {
                  return {
                    id: worksheet.id.toString(),
                    name: worksheet.name,
                    onClick: () => {
                      console.log("clicked", worksheet.id);
                      navigate({
                        to: `/operations/units/worksheet/${worksheet.id}`,
                      });
                    },
                  };
                }),
              };
            }),
          };
        }),
    };
  });

  return (
    <main className="h-auto flex flex-row sm:gap-4 sm:py-4 sm:pl-14">
      <TreeView
        elements={treeData}
        initialExpendedItems={[]}
        className="w-1/2 h-auto"
      />
      <Outlet />
    </main>
  );
}
