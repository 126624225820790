import { createFileRoute, redirect } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import Canvas from "@/components/canvas/canvas";
import { useWindowSize } from "@/components/canvas/hooks/useWindowSize.tsx";
import { useQuery } from "@tanstack/react-query";
import { Spinner } from "@/components/ui/spinner";
import ErrorHandler from "@/components/error-handler";
import WorksheetSetThumbnails from "@/components/worksheet-set-thumbnails";
import { CANVAS_WIDTH, PAGE_HEIGHT } from "@/components/canvas/constants.ts";
import { getWorksheet, getWorksheetSet } from "@/lib/api.ts";

export const Route = createFileRoute(
  "/_auth/teacher/student-worksheets/$studentId/set/$setId/book/$bookId/worksheet/$worksheetId"
)({
  component: () => <TeacherStudentWorksheetsWorksheet />,
  beforeLoad: ({ context, location }) => {
    if (!context.auth.isAuthenticated) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
      });
    }
    if (!context.auth.user?.teacher && !context.auth.user?.ops) {
      throw redirect({ to: "/student" });
    }
  },
});

function TeacherStudentWorksheetsWorksheet() {
  const { bookId, worksheetId, studentId, setId } = Route.useParams();
  const [pageImageUrls, setPageImageUrls] = useState<string[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  console.log("loaded", bookId, worksheetId);

  const { isPending, error, data } = useQuery({
    queryKey: ["get-worksheet", worksheetId],
    queryFn: () => getWorksheet(worksheetId),
  });

  const { data: worksheetSetData } = useQuery({
    queryKey: ["get-worksheet-set", setId],
    queryFn: () => getWorksheetSet(setId),
  });

  const size = useWindowSize();

  useEffect(() => {
    if (!data?.worksheet || !data.worksheet.bookOrder) {
      return;
    }

    const calcTotalPages =
      (data.worksheet.bookEndPage || 0) -
      (data.worksheet.bookStartPage || 0) +
      1;

    const urls = Array.from(
      { length: calcTotalPages },
      (_, i) =>
        `https://edukita-math.s3.ap-southeast-1.amazonaws.com/images/book_${bookId}/${data.worksheet!.bookOrder}-page-${i + 1}.png`
    );

    setPageImageUrls(urls);
    setTotalPages(calcTotalPages);
  }, [data, bookId]);

  let isLastWorksheet = false;
  let nextWorksheetId = null;

  if (worksheetSetData) {
    // determine if this is the last worksheet in the set
    const currentWorksheetIndex = worksheetSetData.worksheetSetItems.findIndex(
      (wsi) => wsi.worksheetId === Number(worksheetId)
    );
    if (
      currentWorksheetIndex ===
      worksheetSetData.worksheetSetItems.length - 1
    ) {
      isLastWorksheet = true;
    } else {
      nextWorksheetId =
        worksheetSetData.worksheetSetItems[currentWorksheetIndex + 1]
          .worksheetId;
    }
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  return (
    <div className="flex flex-col w-full h-auto">
      {isPending ? <Spinner /> : null}

      <WorksheetSetThumbnails
        worksheetSetId={setId}
        currentWorksheetId={worksheetId}
        teacherView={true}
        studentId={studentId}
      />

      <div className="canvas-container min-w-[1032px]">
        <Canvas
          width={size.width}
          height={totalPages * PAGE_HEIGHT}
          setId={setId}
          bookId={bookId}
          studentId={studentId}
          worksheet={{
            currentWorksheetId: worksheetId,
            isLastWorksheet,
            nextWorksheetId,
          }}
          teacherView={true}
        />
        <div className="absolute top-0 z-0" style={{ width: CANVAS_WIDTH }}>
          {pageImageUrls.map((url, index) => (
            <img
              src={url}
              key={index}
              alt={`Page ${index + 1}`}
              className="z-0 m-auto"
            />
          ))}
        </div>
      </div>
    </div>
  );
}
