import { CANVAS_WIDTH, PAGE_HEIGHT } from "@/components/canvas/constants";
import { createFileRoute, redirect } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import Canvas from "@/components/canvas/canvas";
import { useWindowSize } from "@/components/canvas/hooks/useWindowSize.tsx";
import { useQuery } from "@tanstack/react-query";
import { getWorksheet, getWorksheetSet } from "@/lib/api";
import { Spinner } from "@/components/ui/spinner";
import ErrorHandler from "@/components/error-handler";
import WorksheetSetThumbnails from "@/components/worksheet-set-thumbnails";

export const Route = createFileRoute(
  "/_auth/student/set/$setId/book/$bookId/worksheet/$worksheetId"
)({
  component: () => <WorksheetPage />,
  beforeLoad: ({ context, location }) => {
    if (!context.auth.isAuthenticated) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
      });
    }
  },
});

function WorksheetPage() {
  const { setId, bookId, worksheetId } = Route.useParams();
  const [pageImageUrls, setPageImageUrls] = useState<string[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);

  const { isPending, error, data } = useQuery({
    queryKey: ["get-worksheet", worksheetId],
    queryFn: () => getWorksheet(worksheetId),
  });

  const { data: worksheetSetData } = useQuery({
    queryKey: ["get-worksheet-set", setId],
    queryFn: () => getWorksheetSet(setId),
  });

  const size = useWindowSize();

  useEffect(() => {
    if (!data?.worksheet || !data.worksheet.bookOrder) {
      return;
    }

    const calcTotalPages =
      (data.worksheet.bookEndPage || 0) -
      (data.worksheet.bookStartPage || 0) +
      1;

    const urls = Array.from(
      { length: calcTotalPages },
      (_, i) =>
        `https://edukita-math.s3.ap-southeast-1.amazonaws.com/images/book_${bookId}/${data.worksheet!.bookOrder}-page-${i + 1}.png`
    );

    setPageImageUrls(urls);
    setTotalPages(calcTotalPages);
  }, [data, bookId]);

  if (error) {
    return <ErrorHandler error={error} />;
  }

  let isLastWorksheet = false;
  let nextWorksheetId = null;

  if (worksheetSetData) {
    // determine if this is the last worksheet in the set
    const currentWorksheetIndex = worksheetSetData.worksheetSetItems.findIndex(
      (wsi) => wsi.worksheetId === Number(worksheetId)
    );
    if (
      currentWorksheetIndex ===
      worksheetSetData.worksheetSetItems.length - 1
    ) {
      isLastWorksheet = true;
    } else {
      nextWorksheetId =
        worksheetSetData.worksheetSetItems[currentWorksheetIndex + 1]
          .worksheetId;
    }
  }
  return (
    <div className="flex flex-col w-screen h-auto">
      {isPending ? <Spinner /> : null}
      <WorksheetSetThumbnails
        worksheetSetId={setId}
        currentWorksheetId={worksheetId}
      />
      <div className="canvas-container min-w-[1032px]">
        {totalPages > 0 && (
          <Canvas
            width={size.width}
            height={totalPages * PAGE_HEIGHT}
            setId={setId}
            bookId={bookId}
            worksheet={{
              currentWorksheetId: worksheetId,
              isLastWorksheet,
              nextWorksheetId,
            }}
            teacherView={false}
          />
        )}
        <div className="absolute top-0 z-0" style={{ width: CANVAS_WIDTH }}>
          {pageImageUrls.map((url, index) => (
            <img
              src={url}
              key={index}
              alt={`Page ${index + 1}`}
              className="z-0 m-auto"
            />
          ))}
        </div>
      </div>
    </div>
  );
}
