import ErrorHandler from "@/components/error-handler";
import LatestWorksheet from "@/components/latest-worksheet";
import { Separator } from "@/components/ui/separator";
import { Spinner } from "@/components/ui/spinner";
import WorksheetSets from "@/components/worksheet-sets";
import { getAssignmentsForStudent } from "@/lib/api";
import { useQuery } from "@tanstack/react-query";
import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute(
  "/_auth/teacher/student-worksheets/$studentId/"
)({
  component: () => <StudentWorksheets />,
  beforeLoad: ({ context, location }) => {
    if (!context.auth.isAuthenticated) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
      });
    }
    if (!context.auth.user?.teacher && !context.auth.user?.ops) {
      throw redirect({ to: "/student" });
    }
  },
});

function StudentWorksheets() {
  const { studentId } = Route.useParams();

  const { isPending, error, data } = useQuery({
    queryKey: ["user-assignments", studentId],
    queryFn: () => getAssignmentsForStudent(studentId),
  });

  if (isPending) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  return (
    <div className="p-4">
      <LatestWorksheet userId={studentId} teacherView={true} />
      <Separator className="my-4" />

      <h1 className="text-lg font-bold m-12">Worksheets</h1>
      <section className="grid gap-2">
        <WorksheetSets data={data.worksheetSets} studentId={studentId} />
      </section>
    </div>
  );
}
