import { ColumnDef } from "@tanstack/react-table";
import { Badge } from "../ui/badge";

export interface WorksheetSetAssignment {
  id: number;
  fullName: string;
  preferredName: string;
  garageId: number;
  garageUserType: "student" | "teacher" | "ops";
  username: string;
  teacher: boolean;
  ops: boolean;
  worksheetSets: WorksheetSet[];
}

export interface WorksheetSet {
  id: number;
  order: number;
  name: string;
  description: null;
  gaudiaLevelId: number;
}

export const columns: ColumnDef<WorksheetSetAssignment>[] = [
  {
    accessorKey: "garageId",
    header: "Garage ID",
  },
  {
    accessorKey: "username",
    header: "Username",
  },
  {
    accessorKey: "fullName",
    header: "Full Name",
  },
  {
    accessorKey: "preferredName",
    header: "Preferred Name",
  },
  {
    accessorKey: "worksheetSets",
    header: "Worksheet Sets",
    cell: (props) => (
      <div>
        <div className="grid grid-cols-2 max-w-96 mb-2">
          {(props.getValue() as WorksheetSet[]).map((ws) => (
            <Badge variant="outline" className="mr-1 mb-1">
              {ws.name}
            </Badge>
          ))}
        </div>
      </div>
    ),
  },
];
