import { createFileRoute, redirect } from "@tanstack/react-router";
import bgImage from "@/assets/bg.jpg";
import { z } from "zod";
import { Button } from "@/components/ui/button";
import { isStaging } from "@/lib/utils";

const fallback = "/home" as const;

export const Route = createFileRoute("/login")({
  component: Login,
  validateSearch: z.object({
    redirect: z.string().optional().catch(""),
  }),
  beforeLoad: ({ context, search }) => {
    if (context.auth.isAuthenticated) {
      throw redirect({ to: search.redirect || fallback });
    }
  },
});

export function Login() {
  const search = Route.useSearch();

  return (
    <div className="w-full lg:grid lg:grid-cols-2 lg:min-h-screen">
      <div className="flex items-center justify-center py-12">
        <div className="mx-auto grid w-[350px] gap-6">
          <div className="grid gap-2 text-center">
            <h1 className="text-3xl font-bold">Login</h1>
            {search.redirect ? (
              <p className="text-red-400 text-balance">
                You need to login to access this page.
              </p>
            ) : (
              <p className="text-balance text-muted-foreground">
                Login to your account
              </p>
            )}
          </div>
          <Button variant="outline">
            <a
              href={
                isStaging()
                  ? "https://paula-staging.edukita.com"
                  : "https://paula.edukita.com"
              }
            >
              Teacher Login
            </a>
          </Button>
          <Button variant="outline">
            <a
              href={
                isStaging()
                  ? "https://staging-v2.edukita.com"
                  : "https://my.edukita.com"
              }
            >
              Student Login
            </a>
          </Button>
        </div>
      </div>
      <div className="bg-muted block">
        <img
          src={bgImage}
          alt="Image"
          width="1920"
          height="1080"
          className="h-full w-full object-cover dark:brightness-[0.2] dark:grayscale"
        />
      </div>
    </div>
  );
}
