import { getWorksheetSet } from "@/lib/api";
import { useQuery } from "@tanstack/react-query";
import { Spinner } from "../ui/spinner";
import ErrorHandler from "../error-handler";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../ui/carousel";
import { Link } from "@tanstack/react-router";
import { CircleCheck } from "lucide-react";
import { cn } from "@/lib/utils";

interface WorksheetSetThumbnailsProps {
  worksheetSetId: string;
  currentWorksheetId: string;
  teacherView?: boolean;
  studentId?: string;
}

export default function WorksheetSetThumbnails({
  worksheetSetId,
  currentWorksheetId,
  teacherView,
  studentId,
}: WorksheetSetThumbnailsProps) {
  const { isPending, error, data } = useQuery({
    queryKey: ["get-worksheet-set", worksheetSetId],
    queryFn: () => getWorksheetSet(worksheetSetId),
  });
  if (isPending) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  const worksheetSetItems = data.worksheetSetItems.filter(
    (wsi) => wsi.worksheet?.subunit?.unit?.book?.id && wsi.worksheetId
  );

  return (
    <div className="w-2/3 m-auto p-4">
      <div className="w-auto m-auto items-center">
        <Carousel>
          <CarouselContent>
            {worksheetSetItems.map((wsi) => (
              <CarouselItem className="basis-1/8" key={wsi.worksheetId}>
                <div
                  className={cn("w-16", {
                    "border-2 border-primary opacity-100":
                      Number(wsi.worksheetId) === Number(currentWorksheetId),
                  })}
                >
                  {teacherView && studentId ? (
                    <Link
                      to="/teacher/student-worksheets/$studentId/set/$setId/book/$bookId/worksheet/$worksheetId"
                      params={{
                        studentId,
                        setId: worksheetSetId,
                        bookId:
                          wsi.worksheet!.subunit!.unit!.book!.id!.toString(),
                        worksheetId: wsi.worksheetId!.toString(),
                      }}
                      className={cn(
                        " rounded-lg flex items-center justify-center relative",
                        {
                          "bg-green-100":
                            Number(wsi.worksheetId) <
                            Number(currentWorksheetId),
                        }
                      )}
                    >
                      <img
                        src={`https://edukita-math.s3.ap-southeast-1.amazonaws.com/thumbnails/book_${wsi.worksheet?.subunit?.unit?.book?.id}/${wsi.worksheet?.bookOrder}-page-1-small.png`}
                        alt={wsi.worksheet?.name}
                        className={cn("object-cover w-full h-full", {
                          "opacity-50 hover:opacity-100 transition-opacity duration-200":
                            Number(wsi.worksheetId) <
                            Number(currentWorksheetId),
                        })}
                      />
                    </Link>
                  ) : (
                    <Link
                      to="/student/set/$setId/book/$bookId/worksheet/$worksheetId"
                      params={{
                        setId: worksheetSetId,
                        bookId:
                          wsi.worksheet!.subunit!.unit!.book!.id!.toString(),
                        worksheetId: wsi.worksheetId!.toString(),
                      }}
                      className={cn(
                        " rounded-lg flex items-center justify-center relative",
                        {
                          "bg-green-100":
                            Number(wsi.worksheetId) <
                            Number(currentWorksheetId),
                        }
                      )}
                    >
                      <img
                        src={`https://edukita-math.s3.ap-southeast-1.amazonaws.com/thumbnails/book_${wsi.worksheet?.subunit?.unit?.book?.id}/${wsi.worksheet?.bookOrder}-page-1-small.png`}
                        alt={wsi.worksheet?.name}
                        className={cn("object-cover w-full h-full", {
                          "opacity-50 hover:opacity-100 transition-opacity duration-200":
                            Number(wsi.worksheetId) <
                            Number(currentWorksheetId),
                        })}
                      />
                      <CircleCheck
                        className={`absolute top-8 left-3 m-2 ${
                          Number(wsi.worksheetId) < Number(currentWorksheetId)
                            ? "text-green-500"
                            : "hidden"
                        }`}
                      />
                    </Link>
                  )}
                </div>
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPrevious />
          <CarouselNext />
        </Carousel>
      </div>
    </div>
  );
}
