import { hc } from "hono/client";
import { type ApiRoutes } from "@server/app";
import {
  Drawing,
  GroupedWorksheetSetAssignments,
  PaulaSessionStudentResponse,
  PaulaSessions,
  Textbox,
} from "@server/shared-types";

const client = hc<ApiRoutes>("/");

const PAULA_BACKEND_HOST =
  window.location.hostname === "math.edukita.com"
    ? "https://paula-backend.edukita.com"
    : "https://paula-backend-staging.edukita.com";

export const api = client.api;

export const UNAUTHORIZED_ERROR = "Unauthorized";

export async function getAssignments(retry = true) {
  const res = await api.assignments.$get();

  if (!res.ok) {
    if (retry) {
      const refreshRes = await api.auth.refresh.$get();
      if (refreshRes.ok) {
        return getAssignments(false);
      }
    }

    if (res.status === 401) {
      throw new Error(UNAUTHORIZED_ERROR);
    }

    throw new Error("server error");
  }
  const data = await res.json();
  return data as { worksheetSets: GroupedWorksheetSetAssignments[] };
}

export async function getAssignmentsForStudent(
  studentId: string,
  retry = true
) {
  const res = await api.assignments.student[":studentId{[0-9]+}"].$get({
    param: { studentId },
  });

  if (!res.ok) {
    if (retry) {
      const refreshRes = await api.auth.refresh.$get();
      if (refreshRes.ok) {
        return getAssignmentsForStudent(studentId, false);
      }
    }

    if (res.status === 401) {
      throw new Error(UNAUTHORIZED_ERROR);
    }

    throw new Error("server error");
  }
  const data = await res.json();
  return data as { worksheetSets: GroupedWorksheetSetAssignments[] };
}

export async function getWorksheet(id: string, retry = true) {
  const res = await api.worksheet[":id{[0-9]+}"].$get({
    param: { id },
  });
  if (!res.ok) {
    if (retry) {
      const refreshRes = await api.auth.refresh.$get();
      if (refreshRes.ok) {
        return getWorksheet(id, false);
      }
    }

    if (res.status === 401) {
      throw new Error(UNAUTHORIZED_ERROR);
    }

    throw new Error("server error");
  }
  const data = await res.json();
  return data;
}

export async function getWorksheetSet(id: string, retry = true) {
  const res = await api["worksheet-sets"][":id{[0-9]+}"].$get({
    param: { id },
  });
  if (!res.ok) {
    if (retry) {
      const refreshRes = await api.auth.refresh.$get();
      if (refreshRes.ok) {
        return getWorksheetSet(id, false);
      }
    }

    if (res.status === 401) {
      throw new Error(UNAUTHORIZED_ERROR);
    }

    throw new Error("server error");
  }
  const data = await res.json();
  return data;
}

export async function createWorksheetAttempt(
  id: string,
  drawing: Drawing[],
  textboxes: Textbox[],
  retry = true
) {
  const res = await api.worksheet[":id{[0-9]+}"].attempt.$post({
    param: { id },
    json: { drawing, textboxes },
  });

  if (retry) {
    const refreshRes = await api.auth.refresh.$get();
    if (refreshRes.ok) {
      return createWorksheetAttempt(id, drawing, textboxes, false);
    }

    if (res.status === 401) {
      throw new Error(UNAUTHORIZED_ERROR);
    }

    throw new Error("server error");
  }

  const data = await res.json();
  return data;
}

export async function createWorksheetAttemptForStudent(
  id: string,
  studentId: string,
  drawing: Drawing[],
  textboxes: Textbox[],
  retry = true
) {
  const res = await api.worksheet[":id{[0-9]+}"].attempt.student[
    ":student-id{[0-9]+}"
  ].$post({
    param: { id, "student-id": studentId },
    json: { drawing, textboxes },
  });

  if (retry) {
    const refreshRes = await api.auth.refresh.$get();
    if (refreshRes.ok) {
      return createWorksheetAttemptForStudent(
        id,
        studentId,
        drawing,
        textboxes,
        false
      );
    }

    if (res.status === 401) {
      throw new Error(UNAUTHORIZED_ERROR);
    }

    throw new Error("server error");
  }

  const data = await res.json();
  return data;
}

export async function getLatestWorksheetAttempt(id: string, retry = true) {
  const res = await api.worksheet[":id{[0-9]+}"]["latest-attempt"].$get({
    param: { id },
  });

  if (retry) {
    const refreshRes = await api.auth.refresh.$get();
    if (refreshRes.ok) {
      return getLatestWorksheetAttempt(id, false);
    }

    if (res.status === 401) {
      throw new Error(UNAUTHORIZED_ERROR);
    }

    throw new Error("server error");
  }

  const data = await res.json();
  return data;
}

export async function getLatestWorksheetAttemptForStudent(
  id: string,
  studentId: string,
  retry = true
) {
  const res = await api.worksheet[":id{[0-9]+}"]["latest-attempt"].student[
    ":student-id{[0-9]+}"
  ].$get({
    param: { id, "student-id": studentId },
  });

  if (retry) {
    const refreshRes = await api.auth.refresh.$get();
    if (refreshRes.ok) {
      return getLatestWorksheetAttemptForStudent(id, studentId, false);
    }

    if (res.status === 401) {
      throw new Error(UNAUTHORIZED_ERROR);
    }

    throw new Error("server error");
  }

  const data = await res.json();
  return data;
}

export async function getSessions() {
  const res = await fetch(`${PAULA_BACKEND_HOST}/v1/schedule`);
  if (!res.ok) {
    throw new Error("server error");
  }
  const data = (await res.json()) as PaulaSessions;
  return data.schedules;
}

export async function getSessionStudents(id: string) {
  const res = await fetch(`${PAULA_BACKEND_HOST}/v1/session/${id}/students`);
  if (!res.ok) {
    throw new Error("server error");
  }
  const data = (await res.json()) as PaulaSessionStudentResponse;

  return data.students;
}

export async function getLatestWorksheetAttempts() {
  const res = await api.worksheet["latest-attempts"].$get();

  if (!res.ok) {
    throw new Error("server error");
  }

  const data = await res.json();
  return data;
}

export async function getLatestWorksheetAttemptsForStudent(id: string) {
  const res = await api.worksheet["latest-attempts"].student[
    ":id{[0-9]+}"
  ].$get({
    param: { id },
  });

  if (!res.ok) {
    throw new Error("server error");
  }

  const data = await res.json();
  return data;
}

export async function tokenLogin(token: string) {
  return api.auth.token.$post({
    json: { token },
  });
}

export async function getLearningUnits(retry = true) {
  const res = await api["learning-units"].$get();

  if (!res.ok) {
    if (retry) {
      const refreshRes = await api.auth.refresh.$get();
      if (refreshRes.ok) {
        return getLearningUnits(false);
      }
    }

    if (res.status === 401) {
      throw new Error(UNAUTHORIZED_ERROR);
    }

    throw new Error("server error");
  }

  const data = await res.json();
  return data;
}

export async function getWorksheetSetAssignments(retry = true) {
  const res = await api.assignments.all.$get();

  if (!res.ok) {
    if (retry) {
      const refreshRes = await api.auth.refresh.$get();
      if (refreshRes.ok) {
        return getWorksheetSetAssignments(false);
      }
    }

    if (res.status === 401) {
      throw new Error(UNAUTHORIZED_ERROR);
    }

    throw new Error("server error");
  }

  const data = await res.json();
  return data;
}

export async function getAllStudents(retry = true) {
  const res = await api.assignments.students.$get();

  if (!res.ok) {
    if (retry) {
      const refreshRes = await api.auth.refresh.$get();
      if (refreshRes.ok) {
        return getAllStudents(false);
      }
    }

    if (res.status === 401) {
      throw new Error(UNAUTHORIZED_ERROR);
    }

    throw new Error("server error");
  }

  const data = await res.json();
  return data;
}

export async function getAllWorksheetSets(retry = true) {
  const res = await api["worksheet-sets"].$get();

  if (!res.ok) {
    if (retry) {
      const refreshRes = await api.auth.refresh.$get();
      if (refreshRes.ok) {
        return getAllWorksheetSets(false);
      }
    }

    if (res.status === 401) {
      throw new Error(UNAUTHORIZED_ERROR);
    }

    throw new Error("server error");
  }

  const data = await res.json();
  return data;
}

export async function assignWorksheetSet(
  userId: number,
  worksheetSetId: number,
  retry = true
) {
  const res = await api.assignments.$post({
    json: { userId, worksheetSetId },
  });

  if (!res.ok) {
    if (retry) {
      const refreshRes = await api.auth.refresh.$get();
      if (refreshRes.ok) {
        return assignWorksheetSet(userId, worksheetSetId, false);
      }
    }

    if (res.status === 401) {
      throw new Error(UNAUTHORIZED_ERROR);
    }

    throw new Error("server error");
  }

  const data = await res.json();
  return data;
}

export async function unassignWorksheetSet(
  userId: number,
  worksheetSetId: number,
  retry = true
) {
  const res = await api.assignments.$delete({
    json: { userId, worksheetSetId },
  });

  if (!res.ok) {
    if (retry) {
      const refreshRes = await api.auth.refresh.$get();
      if (refreshRes.ok) {
        return unassignWorksheetSet(userId, worksheetSetId, false);
      }
    }

    if (res.status === 401) {
      throw new Error(UNAUTHORIZED_ERROR);
    }

    throw new Error("server error");
  }

  const data = await res.json();
  return data;
}
