import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { getAllStudents } from "@/lib/api";
import { Spinner } from "../ui/spinner";
import ErrorHandler from "../error-handler";
import { useQuery } from "@tanstack/react-query";

interface UserListProps {
  onChange: (value: string) => void;
}

export function UserList({ onChange }: UserListProps) {
  const { isPending, error, data } = useQuery({
    queryKey: ["get-all-students"],
    queryFn: () => getAllStudents(),
  });

  if (isPending) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  return (
    <Select onValueChange={onChange}>
      <SelectTrigger className="w-[280px]">
        <SelectValue placeholder="Select a student" />
      </SelectTrigger>
      <SelectContent>
        {data && (
          <SelectGroup>
            {data.students.map((student) => (
              <SelectItem key={student.id} value={student.id.toString()}>
                {student.fullName} ({student.preferredName}) - Garage ID:{" "}
                {student.garageId}
              </SelectItem>
            ))}
          </SelectGroup>
        )}
      </SelectContent>
    </Select>
  );
}
