import { createFileRoute, Link, redirect } from "@tanstack/react-router";

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useQuery } from "@tanstack/react-query";
import { getSessions } from "@/lib/api";
import { Spinner } from "@/components/ui/spinner";
import { format as formatDate } from "date-fns";
import ErrorHandler from "@/components/error-handler";

export const Route = createFileRoute("/_auth/teacher/")({
  component: () => <SessionsList />,
  beforeLoad: ({ context, location }) => {
    if (!context.auth.isAuthenticated) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
      });
    }
    if (!context.auth.user?.teacher && !context.auth.user?.ops) {
      throw redirect({ to: "/student" });
    }
  },
});

function SessionsList() {
  const { data, isPending, error } = useQuery({
    queryKey: ["get-all-sessions"],
    queryFn: () => getSessions(),
  });

  if (error) {
    return <ErrorHandler error={error} />;
  }

  return (
    <div className="flex min-h-screen w-full flex-col">
      <main className="flex min-h-[calc(100vh_-_theme(spacing.16))] flex-1 flex-col gap-4 bg-muted/40 p-4 md:gap-8 md:p-10">
        <div className="mx-auto grid w-full max-w-6xl gap-2">
          <h1 className="text-3xl font-semibold">My Schedule</h1>
        </div>
        <div className="mx-auto grid w-full max-w-6xl items-start gap-6 md:grid-cols-[180px_1fr] lg:grid-cols-[250px_1fr]">
          <nav
            className="grid gap-4 text-sm text-muted-foreground"
            x-chunk="dashboard-04-chunk-0"
          >
            <Link href="#" className="font-semibold text-primary">
              Upcoming
            </Link>
            <Link to="/operations/worksheet-sets">Worksheet Sets</Link>
            <Link to="/operations/units">Learning Units</Link>
            <Link to="/operations/assignments">Assignments</Link>
          </nav>
          <div className="grid gap-6">
            {isPending && <Spinner />}

            {!isPending &&
              data
                .filter((s) => s.title.includes("Math"))
                .map((session) => (
                  <Card key={session.id}>
                    <CardHeader>
                      <CardTitle>{session.title}</CardTitle>
                      <CardDescription>
                        {session.startTime &&
                          formatDate(session.startTime, "hh:mm a")}{" "}
                        -{" "}
                        {session.endTime &&
                          formatDate(session.endTime, "hh:mm a")}
                        {" | "}
                        {session.startTime &&
                          formatDate(session.startTime, "E, d MMM yyyy")}
                      </CardDescription>
                    </CardHeader>
                    <CardContent>
                      Grade: {session.grade}
                      <br />
                      Topic: {session.topic}
                      <br />
                      Teacher: {session.teacher?.name} ({session.teacher?.email}
                      )
                      <br />
                      Learning Unit: {session.learningUnit?.topic}
                    </CardContent>
                    <CardFooter className="border-t px-6 py-4">
                      <Button variant="outline">
                        <Link
                          to="/teacher/session/$sessionId"
                          params={{ sessionId: session.id }}
                        >
                          View Students
                        </Link>
                      </Button>
                    </CardFooter>
                  </Card>
                ))}
          </div>
        </div>
      </main>
    </div>
  );
}
