import { hotjar } from "react-hotjar";
import { useEffect } from "react";

export default function Hotjar() {
  useEffect(() => {
    if (window && window.location.hostname === "math.edukita.com") {
      hotjar.initialize({
        id: 5086496,
        sv: 6,
      });
    }
  }, []); // Only run once

  return <div className="hidden"></div>;
}
