import { DraggableTextInput } from "@server/shared-types.ts";
import { useState } from "react";

export const useTextInputs = (initialInputs: DraggableTextInput[] = []) => {
  const [textInputs, setTextInputs] =
    useState<DraggableTextInput[]>(initialInputs);

  const updateText = (id: string, text: string) => {
    const newInputs = [...textInputs];
    const index = newInputs.findIndex((input) => input.id === id);
    newInputs[index].text = text;
    setTextInputs(newInputs);
  };

  const removeText = (id: string) => {
    const newInputs = textInputs.filter((input) => input.id !== id);
    setTextInputs(newInputs);
  };

  const updateDragPosition = (id: string, x: number, y: number) => {
    const newInputs = [...textInputs];
    const index = newInputs.findIndex((input) => input.id === id);
    newInputs[index].x = x;
    newInputs[index].y = y;
    setTextInputs(newInputs);
  };

  return {
    textInputs,
    updateText,
    removeText,
    updateDragPosition,
    setTextInputs,
  };
};
