import { createFileRoute, redirect } from "@tanstack/react-router";
import { getAssignments } from "@/lib/api";
import { useAuth } from "@/lib/useAuth";
import { useQuery } from "@tanstack/react-query";
import { Spinner } from "@/components/ui/spinner";

import WorksheetSets from "@/components/worksheet-sets";
import ErrorHandler from "@/components/error-handler";
import LatestWorksheet from "@/components/latest-worksheet";
import { Separator } from "@/components/ui/separator";

export const Route = createFileRoute("/_auth/student/")({
  component: () => <StudentHomePage />,
  beforeLoad: ({ context, location }) => {
    if (!context.auth.isAuthenticated) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
      });
    }
  },
});

function StudentHomePage() {
  const auth = useAuth();

  const { isPending, error, data } = useQuery({
    queryKey: ["user-assignments", auth.user?.id],
    queryFn: () => getAssignments(),
  });

  if (isPending) {
    return <Spinner />;
  }

  if (error || !auth.user?.id) {
    return <ErrorHandler error={error || new Error("Unable to load")} />;
  }

  const fallbackWorksheetSet =
    data.worksheetSets.length && data.worksheetSets[0];

  return (
    <div className="p-4">
      <LatestWorksheet
        userId={auth.user.id.toString()}
        teacherView={false}
        fallback={
          fallbackWorksheetSet
            ? {
                setId: fallbackWorksheetSet.id.toString(),
                bookId: fallbackWorksheetSet.book.id.toString(),
                worksheetId:
                  fallbackWorksheetSet.worksheets[0].order.toString(),
                bookName: fallbackWorksheetSet.book.name,
                worksheetName: fallbackWorksheetSet.worksheets[0].name,
                edukitaLevelName: fallbackWorksheetSet.level.name,
                bookOrder: fallbackWorksheetSet.worksheets[0].order,
              }
            : undefined
        }
      />
      <Separator className="my-4" />

      <h1 className="text-lg font-bold m-12">My Worksheets</h1>
      <section className="grid gap-2">
        <WorksheetSets data={data.worksheetSets} />
      </section>
    </div>
  );
}
