import { useDetectClickOutside } from "react-detect-click-outside";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { useCallback, useEffect, useRef, useState } from "react";
import { cn } from "@/lib/utils";
import {
  TbDragDrop,
  TbCheck,
  TbTextIncrease,
  TbTextDecrease,
  TbTrash,
} from "react-icons/tb";
import { MODES } from "@server/shared-types.ts";

interface EditableTextProps {
  text: string;
  setText: (id: string, text: string) => void;
  id: string;
  remove: (id: string) => void;
  position: {
    x: number;
    y: number;
  };
  onDrag: (id: string, x: number, y: number) => void;
  fontSize: number;
  changeFontSize: (id: string, delta: number) => void;
  mode: MODES; // Add mode prop to track current mode
  saveCanvas: () => void; // For autosave
}

export default function EditableText({
  text,
  setText,
  remove,
  id,
  position,
  onDrag,
  fontSize,
  changeFontSize,
  mode,
  saveCanvas,
}: EditableTextProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [inputWidth, setInputWidth] = useState(50); // Default width
  const inputRef = useRef<HTMLInputElement>(null);
  const toolbarRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const measureTextWidth = useCallback(
    (text: string) => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      if (context) {
        context.font = `${fontSize}px Arial`;
        return context.measureText(text).width;
      }
      return 50; // Fallback width
    },
    [fontSize]
  );

  const handleFocus = () => {
    inputRef.current?.focus();
  };

  const handleBlur = () => {
    setIsEditing(false);
    saveCanvas(); // Save on Blur
  };

  const ref = useDetectClickOutside({
    onTriggered: () => {
      if(isEditing) {
        saveCanvas();
      }
      setIsEditing(false);
      setIsPreview(false);
    },
  });

  useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      if (
        toolbarRef.current &&
        toolbarRef.current.contains(event.target as Node)
      ) {
        event.stopPropagation();
        setIsEditing(true);
        handleFocus();
      }
    };

    document.addEventListener("mousedown", handleMouseDown);
    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  useEffect(() => {
    setInputWidth(measureTextWidth(text) + 20);
  }, [text, fontSize, measureTextWidth]);

  const handleClick = () => {
    if (mode === MODES.PAN && !isPreview) {
      setIsPreview(true);
      return;
    }
    setIsEditing(true);
  };

  const textStyle = `p-1 min-w-fit w-fit whitespace-nowrap mr-2`;

  return (
    <Draggable
      onDrag={(_e: DraggableEvent, data: DraggableData) =>
        onDrag(id, data.x, data.y)
      }
      position={position}
    >
      <div
        className="z-30 absolute editable-text"
        ref={ref}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isEditing && (
          <div
            ref={toolbarRef}
            className="absolute -top-12 left-0 flex items-center space-x-2 bg-gray-200 p-1 shadow-md rounded"
          >
            <div className="cursor-move hover:bg-slate-300">
              <TbDragDrop size={36} />
            </div>
            <div
              className="cursor-pointer hover:bg-slate-300"
              role="button"
              tabIndex={-1}
              onClick={handleBlur}
              onKeyDown={handleBlur}
            >
              <TbCheck size={36} />
            </div>
            <div
              className="cursor-pointer hover:bg-slate-300"
              role="button"
              tabIndex={-1}
              onClick={() => changeFontSize(id, 4)}
              onKeyDown={() => changeFontSize(id, 4)}
            >
              <TbTextIncrease size={36} />
            </div>
            <div
              className="cursor-pointer hover:bg-slate-300"
              role="button"
              tabIndex={-1}
              onClick={() => changeFontSize(id, -4)}
              onKeyDown={() => changeFontSize(id, -4)}
            >
              <TbTextDecrease size={36} />
            </div>
            <div
              className="cursor-pointer hover:bg-slate-300"
              role="button"
              tabIndex={-1}
              onClick={() => remove(id)}
              onKeyDown={() => remove(id)}
            >
              <TbTrash size={36} />
            </div>
          </div>
        )}
        <div
          className={cn(
            "flex items-center py-3 px-4 editable-text-preview-container relative",
            textStyle,
            isHovered || isPreview
              ? "border-2 border-green-500"
              : "border-2 border-transparent",
            isEditing ? "hidden" : "",
            MODES.PEN ? "cursor-pointer" : "",
            MODES.TEXT ? "cursor-text" : ""
          )}
          onClick={handleClick}
          onTouchEnd={handleClick}
          style={{ fontSize: `${fontSize}px` }}
        >
          <span className="editable-text-preview-text">
            {text || "Click to edit"}
          </span>
          {isPreview && (
            <div
              className="absolute -right-6 transform -translate-y-1/2 cursor-pointer top-1/2 text-white bg-green-500 p-1"
              role="button"
              tabIndex={-1}
              onClick={() => remove(id)}
              onKeyDown={() => remove(id)}
            >
              <TbTrash />
            </div>
          )}
        </div>
        {isEditing && (
          <input
            ref={inputRef}
            value={text}
            onChange={(e) => {
              setText(id, e.target.value);
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter" || e.key === "Escape") {
                handleBlur();
              }
            }}
            autoFocus
            style={{ fontSize: `${fontSize}px`, width: `${inputWidth}px` }}
            className={cn(
              "py-3 px-4 bg-white bg-opacity-75 w-max",
              textStyle,
              "outline-2 outline-dashed outline-orange-500"
            )}
          />
        )}
        <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
      </div>
    </Draggable>
  );
}
