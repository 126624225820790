import {
  DrawingHistory,
  DrawingSettings,
  MODES,
  Pair,
} from "@server/shared-types.ts";
import { RefObject, useCallback, useReducer, useRef } from "react";
import { CANVAS_WIDTH } from "@/components/canvas/constants.ts";

interface UseCanvasActionsProps {
  context: RefObject<CanvasRenderingContext2D | null>;
  height: number;
  getContext: (
    config: DrawingSettings | null,
    ctx: CanvasRenderingContext2D | null
  ) => CanvasRenderingContext2D | null;
  drawModes: (
    mode: MODES,
    ctx: CanvasRenderingContext2D,
    point: Pair<number, number> | null,
    path: Pair<number, number>[]
  ) => void;
  saveCanvas: () => void; // For autosave
}

export const useCanvasActions = ({
  context,
  height,
  getContext,
  drawModes,
  saveCanvas,
}: UseCanvasActionsProps) => {
  const history = useRef<DrawingHistory[]>([]);
  const redoHistory = useRef<DrawingHistory[]>([]);
  const [, render] = useReducer((prev) => !prev, false);
  const clearCanvas = useCallback(
    (ctx: CanvasRenderingContext2D) => {
      ctx.save();
      ctx.setTransform(1, 0, 0, 1, 0, 0);
      ctx.clearRect(0, 0, CANVAS_WIDTH, height);
      ctx.restore();
    },
    [height]
  );

  const drawCanvas = useCallback(
    (ctx: CanvasRenderingContext2D) => {
      clearCanvas(ctx);
      for (const item of history.current!) {
        getContext(item, ctx);
        drawModes(item.mode, ctx, null, item.path);
      }
    },
    [clearCanvas, history, getContext, drawModes]
  );

  const undoCanvas = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (history?.current?.length === 0) {
        return;
      }
      const lastUndo = history?.current?.pop();
      if (!lastUndo) {
        return;
      }
      redoHistory?.current?.push(lastUndo);
      const ctx = context.current;
      if (ctx) {
        drawCanvas(ctx);
      }
      render();
      saveCanvas(); // Call saveCanvas after undo action
    },
    [history, redoHistory, context, drawCanvas, render]
  );

  const redoCanvas = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (redoHistory?.current?.length === 0) {
        return;
      }
      const lastRedo = redoHistory?.current?.pop();
      if (!lastRedo) {
        return;
      }
      history?.current?.push(lastRedo);
      const ctx = context.current;
      if (ctx) {
        drawCanvas(ctx);
      }
      render();
      saveCanvas(); // Call saveCanvas after redo action
    },
    [redoHistory, history, context, drawCanvas, render]
  );

  // TODO: refactor to use this to load previous attempts
  const importCanvas = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files || e.target.files.length === 0) return;
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          history.current = JSON.parse(reader.result as string);
          const ctx = context.current;
          if (ctx) drawCanvas(ctx);
          saveCanvas(); // Call saveCanvas after import action
          render();
        }
      };
      reader.readAsText(e.target.files[0]);
    },
    [context, drawCanvas, history, render]
  );

  return {
    history,
    redoHistory,
    clearCanvas,
    drawCanvas,
    undoCanvas,
    redoCanvas,
    importCanvas,
  };
};
