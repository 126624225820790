import { DrawingSettings, MODES } from "@server/shared-types.ts";
import { Skeleton } from "@/components/ui/skeleton";
import moveIcon from "@/assets/move.svg";
import penIcon from "@/assets/pen.svg";
import redoIcon from "@/assets/redo.svg";
import undoIcon from "@/assets/undo.svg";
import textIcon from "@/assets/text.svg";
import eraserIcon from "@/assets/eraser.svg";

interface CanvasToolbarProps {
  drawingSettings: React.RefObject<DrawingSettings>;
  setMode: (mode: MODES) => void;
  changeColor: (color: string) => void;
  undoCanvas: (e: React.MouseEvent<HTMLButtonElement>) => void;
  redoCanvas: (e: React.MouseEvent<HTMLButtonElement>) => void;
  historyLength: number;
  redoHistoryLength: number;
  isLoading?: boolean;
}

const CanvasToolbar: React.FC<CanvasToolbarProps> = ({
  drawingSettings,
  setMode,
  changeColor,
  undoCanvas,
  redoCanvas,
  historyLength = 0,
  redoHistoryLength = 0,
  isLoading = false,
}) => {
  return (
    <div
      className="menu z-20 top-10"
      onPointerDown={(e) => e.stopPropagation()}
      onTouchStart={(e) => e.stopPropagation()}
      onPointerUp={(e) => e.stopPropagation()}
      onTouchEnd={(e) => e.stopPropagation()}
      // aria-disabled={drawingSettings.current?.mode === MODES.PAN}
    >
      {/* General Tools */}
      <button
        className="button"
        type="button"
        onClick={() => setMode(MODES.PAN)}
        aria-pressed={drawingSettings.current?.mode === MODES.PAN}
      >
        {isLoading ? (
          <Skeleton className="w-5 aspect-square" />
        ) : (
          <img src={moveIcon} alt="move" title="move" />
        )}
      </button>
      <hr />

      {/* Text Tools */}
      <button
        className="button"
        type="button"
        onClick={() => setMode(MODES.TEXT)}
        aria-pressed={drawingSettings.current?.mode === MODES.TEXT}
      >
        {isLoading ? (
          <Skeleton className="w-5 aspect-square" />
        ) : (
          <img src={textIcon} alt="text" title="text" />
        )}
      </button>
      <hr />

      {/* Drawing Tools */}
      <button className="button color" type="button">
        {isLoading ? (
          <Skeleton className="w-5 aspect-square rounded-full" />
        ) : (
          <input
            type="color"
            title="change color"
            defaultValue={drawingSettings.current!.color}
            onChange={(e) => changeColor(e.target.value)}
          />
        )}
      </button>
      <button
        className="button"
        type="button"
        onClick={() => setMode(MODES.PEN)}
        aria-pressed={drawingSettings.current?.mode === MODES.PEN}
      >
        {isLoading ? (
          <Skeleton className="w-5 aspect-square" />
        ) : (
          <img src={penIcon} alt="pen" title="pen" />
        )}
      </button>
      <button
        className="button"
        type="button"
        onClick={() => setMode(MODES.ERASER)}
        aria-pressed={drawingSettings.current?.mode === MODES.ERASER}
      >
        {isLoading ? (
          <Skeleton className="w-5 aspect-square" />
        ) : (
          <img src={eraserIcon} alt="eraser" title="eraser" />
        )}
      </button>
      <button
        className="button"
        type="button"
        onClick={undoCanvas}
        disabled={historyLength === 0}
      >
        {isLoading ? (
          <Skeleton className="w-5 aspect-square" />
        ) : (
          <img src={undoIcon} alt="undo" title="undo" />
        )}
      </button>
      <button
        className="button"
        type="button"
        onClick={redoCanvas}
        disabled={redoHistoryLength === 0}
      >
        {isLoading ? (
          <Skeleton className="w-5 aspect-square" />
        ) : (
          <img src={redoIcon} alt="redo" title="redo" />
        )}
      </button>
    </div>
  );
};

export default CanvasToolbar;
