import { useRef, useLayoutEffect } from "react";
import { useToast } from "@/components/ui/use-toast.ts";
import useNavigatorOnline from "@/lib/useNavigatorOnline.ts";

const NetworkStatusToast = () => {
  const firstUpdate = useRef(true);
  const isOnline = useNavigatorOnline();
  const { toast } = useToast();

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    isOnline
      ? toast({
          description: "You are back online",
          duration: 3000,
        })
      : toast({ description: "You are currently offline", duration: 10000 });
  }, [toast, isOnline]);

  return null;
};

export default NetworkStatusToast;
