import { GroupedWorksheetSetAssignments } from "@server/shared-types";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { Button } from "./ui/button";
import { Badge } from "@/components/ui/badge";
import { useNavigate } from "@tanstack/react-router";

export default function WorksheetSets({
  data,
  studentId,
}: {
  data: GroupedWorksheetSetAssignments[];
  studentId?: string;
}) {
  const navigate = useNavigate();

  return (
    <div className="grid grid-cols-3">
      {data.map((group) => (
        <Card key={group.id} className="mx-6 my-2 justify-center align-middle">
          <CardHeader>
            <CardTitle>{group.name}</CardTitle>
          </CardHeader>
          <CardContent>
            <Carousel className="w-full">
              <CarouselContent>
                {group.worksheets.map((w, index) => (
                  <CarouselItem key={index}>
                    <Card
                      key={w.id}
                      className="m-4 flex justify-center align-middle"
                    >
                      <CardContent key={w.id}>
                        <p>{w.subunit.unit.name}</p>
                        <Badge variant="secondary" className="mr-1">
                          {group.book.name}
                        </Badge>

                        <Badge variant="outline">{group.level.name}</Badge>

                        <img
                          src={`https://edukita-math.s3.ap-southeast-1.amazonaws.com/thumbnails/book_${group.book.id}/${w.order}-page-1-small.png`}
                          alt={w.name}
                        />
                      </CardContent>
                    </Card>
                  </CarouselItem>
                ))}
              </CarouselContent>
              <CarouselPrevious />
              <CarouselNext />
            </Carousel>
          </CardContent>
          <CardFooter>
            {studentId ? (
              <Button
                onClick={() =>
                  navigate({
                    to: "/teacher/student-worksheets/$studentId/set/$setId/book/$bookId/worksheet/$worksheetId",
                    params: {
                      studentId: studentId,
                      setId: group.id.toString(),
                      bookId: group.book.id.toString(),
                      worksheetId: group.worksheets[0].id.toString(),
                    },
                  })
                }
                className="w-full"
              >
                View worksheet set
              </Button>
            ) : (
              <Button
                onClick={() =>
                  navigate({
                    to: "/student/set/$setId/book/$bookId/worksheet/$worksheetId",
                    params: {
                      setId: group.id.toString(),
                      bookId: group.book.id.toString(),
                      worksheetId: group.worksheets[0].id.toString(),
                    },
                  })
                }
                className="w-full"
              >
                Start Learning!
              </Button>
            )}
          </CardFooter>
        </Card>
      ))}
    </div>
  );
}
