import { useRouter } from "@tanstack/react-router";
import { UNAUTHORIZED_ERROR } from "@/lib/api";

export default function ErrorHandler({ error }: { error: Error }) {
  const router = useRouter();

  if (error) {
    if (error.message == UNAUTHORIZED_ERROR) {
      router.invalidate().finally(() => {
        router.navigate({ to: "/login" });
      });
    }
    return <div>Error: {error.message}</div>;
  }
}
