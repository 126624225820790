import { AuthContext } from "./lib/useAuth";
import { ReactNode, useEffect, useState } from "react";
import { type User } from "@server/shared-types";
import { api } from "./lib/api";

export function AuthProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!!user);

  const logout = async () => {
    return api.auth.logout.$delete().then(() => {
      setUser(null);
    });
  };

  const getMeAndSetUser = async function () {
    const res = await api.auth.me.$get();
    if (res.ok) {
      const data = await res.json();
      setUser(data);
      setIsAuthenticated(true);
    } else if (res.status === 401) {
      setUser(null);
      setIsAuthenticated(false);
    } else {
      const refreshRes = await api.auth.refresh.$get();
      if (refreshRes.ok) {
        const refreshData = await refreshRes.json();
        setUser(refreshData.user);
        setIsAuthenticated(true);
      } else {
        setUser(null);
        setIsAuthenticated(false);
      }
    }
  };

  useEffect(() => {
    getMeAndSetUser();
  }, [isAuthenticated]);

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, user, getMeAndSetUser, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
}
