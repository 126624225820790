import { createFileRoute } from "@tanstack/react-router";
import {
  WorksheetSetAssignment,
  columns,
} from "@/components/worksheet-set-assignments/columns";
import { DataTable } from "@/components/worksheet-set-assignments/data-table";
import { useQuery } from "@tanstack/react-query";
import { getWorksheetSetAssignments } from "@/lib/api";
import { Spinner } from "@/components/ui/spinner";
import ErrorHandler from "@/components/error-handler";
import { AddAssignmentModal } from "@/components/add-assignment-modal";

export const Route = createFileRoute("/_auth/operations/assignments/")({
  component: () => <WorksheetSetAssignments />,
});

function WorksheetSetAssignments() {
  const { isPending, error, data } = useQuery({
    queryKey: ["get-worksheet-set-assignments"],
    queryFn: () => getWorksheetSetAssignments(),
  });

  if (isPending) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  return (
    <div className="p-4 m-4">
      <h1 className="text-lg mb-4">Worksheet Set Assignments</h1>
      <div>
        <AddAssignmentModal />
      </div>
      <DataTable
        columns={columns}
        data={data?.assignments as WorksheetSetAssignment[]}
      />
    </div>
  );
}
