import useNavigatorOnline from "@/lib/useNavigatorOnline.ts";

const NetworkStatusBanner = () => {
  const isOnline = useNavigatorOnline();
  if (isOnline) {
    return null;
  }

  return (
    <div className="bg-amber-500 text-center p-4">
      You are currently offline. Retrying restore connection...
    </div>
  );
};

export default NetworkStatusBanner;
