import { useQuery } from "@tanstack/react-query";
import { Spinner } from "../ui/spinner";
import { getLatestWorksheetAttemptsForStudent } from "@/lib/api";
import { cn } from "@/lib/utils";
import { Link } from "@tanstack/react-router";
import { Button } from "../ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { Badge } from "../ui/badge";

interface LatestWorksheetProps {
  userId: string;
  teacherView: boolean;
  fallback?: {
    setId: string;
    bookId: string;
    worksheetId: string;
    bookName: string;
    worksheetName: string;
    edukitaLevelName: string;
    bookOrder: number;
  };
}

export default function LatestWorksheet({
  userId,
  teacherView,
  fallback,
}: LatestWorksheetProps) {
  const { data, error, isPending } = useQuery({
    queryKey: ["get-worksheet-attempts-for-user", userId],
    queryFn: () => getLatestWorksheetAttemptsForStudent(userId),
    staleTime: 5 * 1000,
  });

  const lastAttemptedWorksheet =
    (data &&
      data.worksheetAttempts
        .sort(
          (a, b) =>
            Number(new Date(a.startedAt)) - Number(new Date(b.startedAt))
        )
        .reverse()[0]) ||
    fallback;

  console.log({ lastAttemptedWorksheet, data });

  return (
    <div className="grid grid-cols-3">
      <Card className="m-12 justify-center align-middle">
        <CardHeader>
          <CardTitle>
            <h1 className="text-lg font-bold">
              {teacherView ? "Latest worksheet" : "Today"}
            </h1>
          </CardTitle>
        </CardHeader>
        <CardContent>
          {isPending && <Spinner />}
          {error && <div>{error.message}</div>}
          <div className="w-auto m-auto">
            {lastAttemptedWorksheet && (
              <div className="mx-4 flex flex-col justify-center align-middle">
                <div>
                  <Badge variant="secondary" className="mr-1">
                    {lastAttemptedWorksheet.bookName}
                  </Badge>

                  <Badge variant="outline">
                    Level {lastAttemptedWorksheet.edukitaLevelName}
                  </Badge>
                </div>
                <Link
                  to="/student/set/$setId/book/$bookId/worksheet/$worksheetId"
                  params={{
                    setId: lastAttemptedWorksheet?.setId!.toString(),
                    bookId: lastAttemptedWorksheet.bookId!.toString(),
                    worksheetId: lastAttemptedWorksheet.worksheetId!.toString(),
                  }}
                  className="rounded-lg flex items-center justify-center align-middle relative border-3 border-primary opacity-100 w-36 m-auto"
                >
                  <img
                    src={`https://edukita-math.s3.ap-southeast-1.amazonaws.com/thumbnails/book_${lastAttemptedWorksheet.bookId!.toString()}/${lastAttemptedWorksheet?.bookOrder}-page-1-small.png`}
                    alt={lastAttemptedWorksheet.worksheetName!}
                    className={cn("object-cover w-full h-full")}
                  />
                </Link>
                <p className="text-sm p-4">
                  {lastAttemptedWorksheet.worksheetName!}
                </p>
                {teacherView ? (
                  <Button>
                    <Link
                      to="/teacher/student-worksheets/$studentId/set/$setId/book/$bookId/worksheet/$worksheetId"
                      params={{
                        studentId: userId,
                        setId: lastAttemptedWorksheet?.setId!.toString(),
                        bookId: lastAttemptedWorksheet.bookId!.toString(),
                        worksheetId:
                          lastAttemptedWorksheet.worksheetId!.toString(),
                      }}
                    >
                      View Worksheet &rarr;
                    </Link>
                  </Button>
                ) : (
                  <Button>
                    <Link
                      to="/student/set/$setId/book/$bookId/worksheet/$worksheetId"
                      params={{
                        setId: lastAttemptedWorksheet?.setId!.toString(),
                        bookId: lastAttemptedWorksheet.bookId!.toString(),
                        worksheetId:
                          lastAttemptedWorksheet.worksheetId!.toString(),
                      }}
                    >
                      Continue Worksheet &rarr;
                    </Link>
                  </Button>
                )}
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
