import { getLatestWorksheetAttempts, getSessionStudents } from "@/lib/api";
import { createFileRoute, Link, redirect } from "@tanstack/react-router";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Spinner } from "@/components/ui/spinner";
import ErrorHandler from "@/components/error-handler";
import { useQuery } from "@tanstack/react-query";
import { Button } from "@/components/ui/button";
import { ArrowLeft } from "lucide-react";
import { Badge } from "@/components/ui/badge";

export const Route = createFileRoute("/_auth/teacher/session/$sessionId")({
  component: () => <SessionStudentList />,
  beforeLoad: ({ context, location }) => {
    if (!context.auth.isAuthenticated) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
      });
    }
    if (!context.auth.user?.teacher && !context.auth.user?.ops) {
      throw redirect({ to: "/student" });
    }
  },
});

function SessionStudentList() {
  const { sessionId } = Route.useParams();

  const { data, isPending, error } = useQuery({
    queryKey: ["get-session-students"],
    queryFn: () => getSessionStudents(sessionId),
  });

  const { data: worksheetAttempts } = useQuery({
    queryKey: ["get-worksheet-session-attempts"],
    queryFn: () => getLatestWorksheetAttempts(),
  });

  if (error) {
    return <ErrorHandler error={error} />;
  }

  if (isPending) {
    return <Spinner />;
  }

  const currentSessionStudentWorksheetAttempts =
    worksheetAttempts?.worksheetAttempts.filter((attempt) =>
      data.some((student) => student.id === attempt.id.toString())
    );

  return (
    <div className="flex min-h-screen w-full flex-col">
      <main className="flex min-h-[calc(100vh_-_theme(spacing.16))] flex-1 flex-col gap-4 bg-muted/40 p-4 md:gap-8 md:p-10">
        <div className="mx-auto grid w-full max-w-6xl gap-2">
          <h1 className="text-3xl font-semibold">Student list</h1>
        </div>
        <div className="mx-auto grid w-full max-w-6xl items-start gap-6 md:grid-cols-[180px_1fr] lg:grid-cols-[250px_1fr]">
          <nav
            className="grid gap-4 text-sm text-muted-foreground"
            x-chunk="dashboard-04-chunk-0"
          >
            <Link to="/teacher">
              <span className="flex items-center gap-2 text-sm">
                <ArrowLeft /> Return to session list
              </span>
            </Link>
          </nav>
          <div className="grid gap-6">
            <Table>
              <TableCaption>Students for the selected session</TableCaption>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[100px]">ID</TableHead>
                  <TableHead>Name</TableHead>
                  <TableHead>Nickname</TableHead>
                  <TableHead>Current Worksheet</TableHead>
                  <TableHead className="text-right">Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {currentSessionStudentWorksheetAttempts &&
                  currentSessionStudentWorksheetAttempts.map((attempt) => (
                    <TableRow key={attempt.id}>
                      <TableCell className="font-medium">
                        {attempt.id}
                      </TableCell>
                      <TableCell>{attempt.name}</TableCell>
                      <TableCell>{attempt.nickName}</TableCell>
                      <TableCell>
                        {attempt.worksheetName}
                        <br />
                        <Badge variant="outline">{attempt.subUnitName}</Badge>
                        <br />
                        <Badge variant="outline">{attempt.unitName}</Badge>
                        <br />
                        <Badge variant="secondary">{attempt.bookName}</Badge>
                      </TableCell>
                      <TableCell className="text-right">
                        <Button variant="outline">
                          <Link
                            to="/teacher/student-worksheets/$studentId"
                            params={{ studentId: attempt.userId!.toString() }}
                          >
                            View worksheets
                          </Link>
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </main>
    </div>
  );
}
