import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function isStaging() {
  return window && window.location.hostname !== "math.edukita.com";
}

export function unique<T>(arr: T[]) {
  return Array.from(new Set(arr));
}
