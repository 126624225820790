import {
  MutableRefObject,
  PointerEvent,
  RefObject,
  TouchEvent as ReactTouchEvent,
  MouseEvent as ReactMouseEvent,
  useCallback,
  useRef,
  useState,
} from "react";
import {
  DraggableTextInput,
  DrawingHistory,
  DrawingSettings,
  MODES,
  Pair,
  Pairs,
} from "@server/shared-types.ts";
import { DEFAULT_FONT_SIZE, PAN_LIMIT } from "@/components/canvas/constants.ts";

import { v4 as uuidv4 } from "uuid";

interface UseCanvasEventsProps {
  history: MutableRefObject<DrawingHistory[]>;
  redoHistory: MutableRefObject<DrawingHistory[]>;
  canvas: RefObject<HTMLCanvasElement>;
  width: number;
  height: number;
  drawingSettings: RefObject<DrawingSettings>;
  context: RefObject<CanvasRenderingContext2D | null>;
  textInputs: DraggableTextInput[];
  setTextInputs: (inputs: DraggableTextInput[]) => void;
  setMode: (mode: MODES) => void;
  getContext: (
    config: DrawingSettings | null,
    ctx: CanvasRenderingContext2D | null
  ) => CanvasRenderingContext2D | null;
  drawModes: (
    mode: MODES,
    ctx: CanvasRenderingContext2D,
    point: Pair<number, number> | null,
    path: Pair<number, number>[]
  ) => void;
  drawCanvas: (ctx: CanvasRenderingContext2D) => void;
  saveCanvas: () => void; // For autosave
}

export const useCanvasEvents = ({
  canvas,
  history,
  redoHistory,
  drawCanvas,
  width,
  height,
  drawingSettings,
  context,
  textInputs,
  setTextInputs,
  setMode,
  getContext,
  drawModes,
  saveCanvas,
}: UseCanvasEventsProps) => {
  const prevent = useCallback(
    (
      e:
        | PointerEvent
        | MouseEvent
        | ReactMouseEvent<HTMLButtonElement, MouseEvent>
        | ReactTouchEvent<Element>
        | globalThis.TouchEvent
    ) => {
      e.preventDefault();
      e.stopPropagation();
    },
    []
  );

  const [drawing, setDrawing] = useState(false);
  const moving = useRef(false);
  const draw = useRef(false);
  const coords = useRef([0, 0]);
  const lastPath: MutableRefObject<Pairs<number, number>> = useRef([]);

  const getPoints = useCallback(
    (
      e:
        | PointerEvent<Element>
        | PointerEvent<HTMLCanvasElement>
        | globalThis.PointerEvent,
      ctx: CanvasRenderingContext2D | null
    ): Pair<number, number> => {
      if (!canvas.current || !ctx) {
        return [0, 0];
      }

      const { e: dx, f: dy } = ctx.getTransform();
      const rect = canvas.current.getBoundingClientRect();

      return [e.clientX - rect.x - dx, e.clientY - rect.y - dy];
    },
    [canvas]
  );

  const getTouchPoints = useCallback(
    (
      e: ReactTouchEvent<HTMLCanvasElement>,
      ctx: CanvasRenderingContext2D | null
    ): Pair<number, number>[] => {
      if (!canvas.current || !ctx) {
        return [[0, 0]];
      }

      const { e: dx, f: dy } = ctx.getTransform();
      const rect = canvas.current.getBoundingClientRect();

      return (e.touches as unknown as Array<Touch>).map((t) => [
        t.clientX - rect.x - dx,
        t.clientY - rect.y - dy,
      ]);
    },
    [canvas]
  );

  const onPointerDown = useCallback(
    (e: PointerEvent | globalThis.PointerEvent) => {
      prevent(e);
      getContext(drawingSettings.current!, null);
      coords.current = [e.clientX, e.clientY];
      if (drawingSettings.current!.mode === MODES.PAN) {
        return;
      }
      if ([MODES.ERASER, MODES.TEXT].includes(drawingSettings.current!.mode)) {
        return;
      }

      setDrawing(true);
      draw.current = true;
      const point = getPoints(e, context.current!);
      lastPath.current = [];
      drawModes(
        drawingSettings.current!.mode,
        context.current!,
        point,
        lastPath.current
      );
    },
    [
      drawingSettings,
      getContext,
      coords,
      setDrawing,
      prevent,
      getPoints,
      drawModes,
      context,
      draw,
    ]
  );

  const onPointerUp = useCallback(
    (e: PointerEvent<HTMLCanvasElement> | globalThis.PointerEvent) => {
      prevent(e);

      // Check if the cursor is hovering over an existing EditableText component
      const elementUnderCursor = document.elementFromPoint(
        e.clientX,
        e.clientY
      );
      if (
        elementUnderCursor?.classList.contains("editable-text") ||
        elementUnderCursor?.classList.contains(
          "editable-text-preview-container"
        ) ||
        elementUnderCursor?.classList.contains("editable-text-preview-text")
      ) {
        // return and do nothing if true
        return;
      }

      if (drawingSettings.current!.mode === MODES.PAN) {
        moving.current = false;
        return;
      }

      if (drawingSettings.current!.mode === MODES.ERASER) {
        const points = getPoints(e, context.current);

        const newHistory = history.current!.filter((item) => {
          for (const [x, y] of item.path) {
            if (
              Math.abs(x - points[0]) <= 10 &&
              Math.abs(y - points[1]) <= 10
            ) {
              return false;
            }
          }
          return true;
        });

        history.current = newHistory;

        drawCanvas(context.current!);
        setDrawing(false);
        saveCanvas(); // Call saveCanvas after erase action
        return;
      }

      if (drawingSettings.current!.mode === MODES.TEXT) {
        const newInputs = [...textInputs];
        newInputs.push({
          id: uuidv4(),
          x: (e as globalThis.PointerEvent).offsetX,
          y: (e as globalThis.PointerEvent).offsetY,
          text: "",
          fontSize: drawingSettings.current?.fontSize ?? DEFAULT_FONT_SIZE,
        });
        setTextInputs(newInputs);
        setDrawing(false);
        setMode(MODES.PAN);
        saveCanvas(); // Call saveCanvas after import action
        return;
      }

      setDrawing(false);
      draw.current = false;
      if (lastPath?.current?.length) {
        history.current!.push({
          ...drawingSettings.current!,
          path: lastPath.current,
        });
        redoHistory.current = [];
        lastPath.current = [];
        const ctx = getContext(null, null);

        if (ctx) {
          drawCanvas(ctx);
        }
        saveCanvas(); // Call saveCanvas after drawing
      }
    },
    [
      context,
      drawingSettings,
      textInputs,
      setDrawing,
      setMode,
      setTextInputs,
      history,
      redoHistory,
      getContext,
      drawCanvas,
      prevent,
      getPoints,
    ]
  );

  const onPointerMove = useCallback(
    (e: PointerEvent | globalThis.PointerEvent) => {
      prevent(e);
      if (drawingSettings.current!.mode === MODES.PAN) {
        return;
      }
      if (!draw.current) {
        return;
      }
      const point = getPoints(e, context.current);
      drawModes(
        drawingSettings.current!.mode,
        context.current!,
        point,
        lastPath.current
      );
    },
    [drawingSettings, context, draw, prevent, getPoints, drawModes]
  );

  const onTouchStart = useCallback(
    (e: ReactTouchEvent | globalThis.TouchEvent) => {
      prevent(e);
      getContext(drawingSettings.current!, null);
      for (let i = 0; i < e.touches.length; i++) {
        coords.current = [e.touches[i].clientX, e.touches[i].clientY];
      }
      if (drawingSettings.current!.mode === MODES.PAN) {
        return;
      }
      setDrawing(true);
      draw.current = true;
      const point = getTouchPoints(
        e as unknown as ReactTouchEvent<HTMLCanvasElement>,
        context.current!
      )[0];
      lastPath.current = [];
      drawModes(
        drawingSettings.current!.mode,
        context.current!,
        point,
        lastPath.current
      );
    },
    [
      drawingSettings,
      getTouchPoints,
      getContext,
      coords,
      setDrawing,
      prevent,
      drawModes,
      context,
      draw,
    ]
  );

  const onTouchEnd = useCallback(
    (e: ReactTouchEvent<HTMLCanvasElement> | globalThis.TouchEvent) => {
      prevent(e);
      if (drawingSettings.current!.mode === MODES.PAN) {
        moving.current = false;
        return;
      }
      setDrawing(false);
      draw.current = false;
      if (lastPath?.current?.length > 0) {
        history.current!.push({
          ...drawingSettings.current!,
          path: lastPath.current,
        });
        redoHistory.current = [];
        lastPath.current = [];
        const ctx = getContext(null, null);

        if (ctx) {
          drawCanvas(ctx);
        }
      }
    },
    [
      drawingSettings,
      setDrawing,
      history,
      redoHistory,
      getContext,
      drawCanvas,
      draw,
      prevent,
    ]
  );

  const onCanvasMove = useCallback(
    (
      e:
        | PointerEvent<HTMLCanvasElement>
        | globalThis.PointerEvent
        | Touch
        | React.Touch,
      ctx: CanvasRenderingContext2D
    ) => {
      const [x1, y1] = coords.current;
      const { clientX: x2, clientY: y2 } = e;
      const dx = x2 - x1;
      const dy = y2 - y1;
      if (Math.abs(dx) < 1 && Math.abs(dy) < 1) return;
      const { e: tdx, f: tdy } = ctx.getTransform();
      const ntdx = Math.min(Math.max(-(PAN_LIMIT - width), tdx + dx), 0);
      const ntdy = Math.min(Math.max(-(PAN_LIMIT - height), tdy + dy), 0);
      ctx.setTransform(1, 0, 0, 1, ntdx, ntdy);
      drawCanvas(ctx);
      coords.current = [x2, y2];
    },
    [drawCanvas, height, width]
  );

  const onTouchMove = useCallback(
    (e: TouchEvent | globalThis.TouchEvent) => {
      prevent(e);
      if (moving.current) {
        return onCanvasMove(e.touches[0], context.current!);
      }
      if (!draw.current) {
        return;
      }
      const points = getTouchPoints(
        e as unknown as ReactTouchEvent<HTMLCanvasElement>,
        context.current
      );
      drawModes(
        drawingSettings.current!.mode,
        context.current!,
        points[0],
        lastPath.current
      );
    },
    [
      drawingSettings,
      context,
      draw,
      prevent,
      drawModes,
      getTouchPoints,
      onCanvasMove,
    ]
  );

  return {
    onPointerDown,
    onPointerUp,
    onPointerMove,
    onTouchStart,
    onTouchEnd,
    onTouchMove,
    history,
    redoHistory,
    drawing,
    moving,
    lastPath,
    getPoints,
    getTouchPoints,
  };
};
