import { Outlet, createFileRoute } from "@tanstack/react-router";
import { TreeView } from "@/components/ui/tree-view";
import { useQuery } from "@tanstack/react-query";
import { getAllWorksheetSets } from "@/lib/api";
import { Spinner } from "@/components/ui/spinner";
import ErrorHandler from "@/components/error-handler";
import { unique } from "@/lib/utils";

export const Route = createFileRoute("/_auth/operations/worksheet-sets")({
  component: () => <OperationsPage />,
});

function OperationsPage() {
  const { data, error, isLoading } = useQuery({
    queryKey: ["get-all-worksheet-sets"],
    queryFn: () => getAllWorksheetSets(),
  });

  const navigate = Route.useNavigate();

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return <ErrorHandler error={error} />;
  }

  const gaudiaLevels = data
    ? unique(data.map((wss) => wss!.gaudiaLevelId))
    : [];

  const treeData = gaudiaLevels
    .filter((x) => x !== null)
    .map((level) => {
      const wss =
        (data || []).filter((wss) => wss.gaudiaLevelId === level) || [];
      return {
        id: level!.toString(),
        name: `Level ${level}`,
        children: wss
          .filter((wss) => wss.gaudiaLevelId === level)
          .map((wss) => {
            return {
              id: wss.id!.toString(),
              name: wss.name,
              children: wss.worksheetSetItems
                .filter((wsi) => wsi.worksheetId != null)
                .map((wsi) => {
                  return {
                    id: wsi.worksheetId!.toString(),
                    name: wsi.worksheet!.name || "",
                    onClick: () => {
                      console.log("clicked", wsi.worksheet!.id);
                      navigate({
                        to: `/operations/worksheet-sets/worksheet/${wsi.worksheet!.id}`,
                      });
                    },
                  };
                }),
            };
          }),
      };
    });

  return (
    <main className="h-auto flex flex-row sm:gap-4 sm:py-4 sm:pl-14">
      <TreeView
        elements={treeData}
        initialExpendedItems={[]}
        className="w-1/2 h-screen"
      />
      <Outlet />
    </main>
  );
}
