import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { UserList } from "./user-list";
import { useState } from "react";
import { PlusCircleIcon } from "lucide-react";
import { WorksheetSetList } from "./worksheet-set-list";
import { useMutation } from "@tanstack/react-query";
import { assignWorksheetSet } from "@/lib/api";
import { toast } from "sonner";

export function AddAssignmentModal() {
  const [userId, setUserId] = useState<string | null>(null);
  const [worksheetSetId, setWorksheetSetId] = useState<string | null>(null);

  console.log({ userId, worksheetSetId });

  const mutation = useMutation({
    mutationFn: (data: { userId: number; worksheetSetId: number }) => {
      return assignWorksheetSet(data.userId, data.worksheetSetId);
    },
    onSuccess: () => {
      toast.success("Saved!");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const handleSave = () => {
    if (!userId || !worksheetSetId) {
      return;
    }
    mutation.mutate({
      userId: parseInt(userId as string),
      worksheetSetId: parseInt(worksheetSetId as string),
    });
  };

  return (
    <Dialog>
      <div className="w-full flex justify-end m-4">
        <DialogTrigger asChild>
          <Button variant="secondary" size="sm">
            <PlusCircleIcon className="mr-2 h-4 w-4" /> Add Worksheet Set
          </Button>
        </DialogTrigger>
      </div>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Assign Worksheet Set</DialogTitle>
          <DialogDescription>
            Assign a new Worksheet Set to a student
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="text-right">
              Student
            </Label>
            <UserList onChange={setUserId} />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="username" className="text-right">
              Worksheet Set
            </Label>
            <WorksheetSetList onChange={setWorksheetSetId} />
          </div>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="secondary">Cancel</Button>
          </DialogClose>
          <DialogClose asChild>
            <Button
              type="submit"
              disabled={!userId || !setWorksheetSetId}
              onClick={handleSave}
            >
              Save changes
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
