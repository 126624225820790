import { DrawingSettings, MODES } from "@server/shared-types.ts";
import { useReducer, useRef } from "react";

export const useDrawingSettings = (initialSettings: DrawingSettings) => {
  const drawingSettings = useRef<DrawingSettings>(initialSettings);
  const [, render] = useReducer((prev) => !prev, false);

  const setMode = (mode: MODES) => {
    drawingSettings.current.mode = mode;
    render();
  };

  const changeColor = (color: string) => {
    drawingSettings.current.color = color;
  };

  const changeFontSize = (fontSize: number) => {
    drawingSettings.current.fontSize = fontSize;
  };

  return { drawingSettings, setMode, changeColor, changeFontSize };
};
