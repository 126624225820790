import { useEffect, useRef, useCallback, useReducer } from "react";
import { CANVAS_WIDTH, DEFAULT_FONT_SIZE, PAN_LIMIT } from "./constants";
import {
  MODES,
  DrawingSettings,
  Pair,
  Pairs,
  DrawingHistory,
  DraggableTextInput,
} from "@server/shared-types";
import exportIcon from "@/assets/export.svg";
import { ArrowBigRightDash } from "lucide-react";
import { cn } from "@/lib/utils";
import { useNavigate } from "@tanstack/react-router";
import {
  createWorksheetAttempt,
  createWorksheetAttemptForStudent,
  getLatestWorksheetAttempt,
  getLatestWorksheetAttemptForStudent,
} from "@/lib/api";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Spinner } from "../ui/spinner";

import { v4 as uuidv4 } from "uuid";
import EditableText from "../editable-text";
import { Textbox } from "@server/shared-types";
import useNavigatorOnline from "@/lib/useNavigatorOnline.ts";
import { useDrawingSettings, useTextInputs } from "@/components/canvas/hooks";
import CanvasToolbar from "@/components/canvas/canvas-toolbar.tsx";
import { useCanvasActions } from "@/components/canvas/hooks/useCanvasActions.tsx";
import { useCanvasEvents } from "@/components/canvas/hooks/useCanvasEvents.tsx";
import { Button } from "@/components/ui/button.tsx";
import { toast } from "sonner";
import { captureException } from "@sentry/react";
import { Skeleton } from "@/components/ui/skeleton";

interface CanvasProps {
  width: number;
  height: number;
  setId: string;
  bookId: string;
  worksheet: {
    currentWorksheetId: string;
    isLastWorksheet?: boolean | null;
    nextWorksheetId?: number | null;
  };
  teacherView: boolean;
  studentId?: string;
}

const Canvas = ({ ...rest }: CanvasProps) => {
  // const history = useRef<DrawingHistory[]>([]);
  const width = Math.min(rest.width, PAN_LIMIT);
  const height = rest.height;
  const canvas = useRef<HTMLCanvasElement>(null);
  const context = useRef<CanvasRenderingContext2D | null>(null);
  const importInput = useRef(null);

  const [, render] = useReducer((prev) => !prev, false);

  const {
    textInputs,
    setTextInputs,
    updateText,
    removeText,
    updateDragPosition,
  } = useTextInputs([]);
  const {
    drawingSettings,
    setMode,
    changeColor,
    changeFontSize: changeDrawingFontSize,
  } = useDrawingSettings({
    stroke: 3,
    color: "#000",
    mode: MODES.PEN,
    fontSize: DEFAULT_FONT_SIZE,
  });

  const getContext = useCallback(
    (config: DrawingSettings | null, ctx: CanvasRenderingContext2D | null) => {
      if (!context.current) {
        context.current = canvas.current!.getContext("2d");
      }
      if (!ctx && context.current) {
        ctx = context.current;
      }
      if (config && ctx) {
        ctx.strokeStyle = config.color;
        ctx.lineWidth = config.stroke;
        ctx.lineCap = "round";
        ctx.lineJoin = "round";
      }
      return ctx;
    },
    []
  );

  const drawModes = (
    mode: MODES,
    ctx: CanvasRenderingContext2D,
    point: Pair<number, number> | null,
    path: Pairs<number, number>
  ) => {
    switch (mode) {
      case MODES.PEN:
        point ? previewPen(point, ctx) : drawPen(path, ctx);
        break;
      case MODES.RECT:
        if (point) {
          path.length === 0 ? (path[0] = point) : (path[1] = point);
          previewRect(path, ctx);
        } else {
          drawRect(path, ctx);
        }
        break;
      case MODES.CIRCLE:
        if (point) {
          path.length === 0 ? (path[0] = point) : (path[1] = point);
          previewCircle(path, ctx);
        } else {
          drawCircle(path, ctx);
        }
        break;
      default:
        return;
    }
  };

  const isOnline = useNavigatorOnline();

  const mutation = useMutation({
    mutationFn: ({
      drawingHistories,
      textInputs,
    }: {
      drawingHistories: DrawingHistory[];
      textInputs: DraggableTextInput[];
    }) => {
      const textboxes = textInputs.map((ti) => ({
        text: ti.text,
        position: {
          x: ti.x,
          y: ti.y,
        },
        fontSize: ti.fontSize,
      }));

      if (rest.teacherView && !rest.studentId) {
        captureException("student id in teacher view is not found");
        return Promise.reject(
          new Error("student id in teacher view is not found")
        );
      }

      if (rest.teacherView && rest.studentId) {
        return createWorksheetAttemptForStudent(
          rest.worksheet.currentWorksheetId,
          rest.studentId,
          drawingHistories,
          textboxes
        );
      }
      return createWorksheetAttempt(
        rest.worksheet.currentWorksheetId,
        drawingHistories,
        textboxes
      );
    },
    onSuccess: () => {
      toast.success("Saved!");
    },
  });

  const saveCanvas = (
    drawingHistories: DrawingHistory[],
    textInputs: DraggableTextInput[]
  ) => {
    mutation.mutate({ drawingHistories, textInputs });
  };

  const saveCanvasHandler = () => {
    saveCanvas(history.current, textInputs);
  };

  const {
    clearCanvas,
    drawCanvas,
    undoCanvas,
    redoCanvas,
    importCanvas,
    history,
    redoHistory,
  } = useCanvasActions({
    context,
    height,
    getContext,
    drawModes,
    saveCanvas: saveCanvasHandler,
  });

  const {
    drawing,
    lastPath,
    onPointerDown,
    onPointerUp,
    onPointerMove,
    onTouchStart,
    onTouchEnd,
    onTouchMove,
  } = useCanvasEvents({
    canvas,
    history,
    redoHistory,
    drawCanvas,
    width,
    height,
    drawingSettings,
    context,
    textInputs,
    setTextInputs,
    setMode,
    getContext,
    drawModes,
    saveCanvas: saveCanvasHandler,
  });

  const saveButtonClickHandler = () => {
    if (rest.worksheet.isLastWorksheet) {
      saveCanvas(history.current, textInputs);
      const redirectURI = rest.teacherView ? "/teacher" : "/student";
      navigate({ to: redirectURI });
    } else {
      saveCanvas(history.current, textInputs);
      const nextPageURI = rest.teacherView
        ? `/teacher/student-worksheets/${rest.studentId}/set/${rest.setId}/book/${rest.bookId}/worksheet/${rest.worksheet.nextWorksheetId}`
        : `/student/set/${rest.setId}/book/${rest.bookId}/worksheet/${rest.worksheet.nextWorksheetId}`;
      navigate({
        to: nextPageURI,
      });
    }
  };

  useEffect(() => {
    if (isOnline && history?.current?.length) {
      saveCanvasHandler();
    }
  }, [isOnline]);

  const { isPending, error, data } = useQuery({
    queryKey: [
      "get-latest-attempt",
      rest.worksheet.currentWorksheetId,
      rest.studentId,
    ],
    queryFn: () =>
      rest.teacherView && rest.studentId
        ? getLatestWorksheetAttemptForStudent(
            rest.worksheet.currentWorksheetId,
            rest.studentId
          )
        : getLatestWorksheetAttempt(rest.worksheet.currentWorksheetId),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 5,
  });

  const navigate = useNavigate();

  const previewRect = (
    path: Pairs<number, number>,
    ctx: CanvasRenderingContext2D
  ) => {
    if (path.length < 2) return;
    drawCanvas(ctx);
    const newCtx = getContext(drawingSettings.current, ctx);
    if (!newCtx) {
      return;
    }
    drawRect(path, newCtx);
  };

  const drawRect = (
    path: Pairs<number, number>,
    ctx: CanvasRenderingContext2D
  ) => {
    ctx.beginPath();
    ctx.rect(
      path[0][0],
      path[0][1],
      path[1][0] - path[0][0],
      path[1][1] - path[0][1]
    );
    ctx.stroke();
  };

  const previewCircle = (
    path: Pairs<number, number>,
    ctx: CanvasRenderingContext2D
  ) => {
    if (path.length < 2) return;
    drawCanvas(ctx);
    getContext(drawingSettings.current!, ctx); // reset context
    drawCircle(path, ctx);
  };

  const getDistance = ([[p1X, p1Y], [p2X, p2Y]]: Pairs<number, number>) => {
    return Math.sqrt(Math.pow(p1X - p2X, 2) + Math.pow(p1Y - p2Y, 2));
  };

  const drawCircle = (
    path: Pairs<number, number>,
    ctx: CanvasRenderingContext2D
  ) => {
    ctx.beginPath();
    ctx.arc(path[0][0], path[0][1], getDistance(path), 0, 2 * Math.PI);
    ctx.stroke();
  };

  const previewPen = (
    point: Pair<number, number>,
    ctx: CanvasRenderingContext2D
  ) => {
    if (lastPath.current.length === 0) {
      ctx.beginPath();
      ctx.moveTo(point[0], point[1]);
    }
    ctx.lineTo(point[0], point[1]);
    ctx.stroke();
    lastPath.current.push(point);
  };

  const drawPen = (
    points: Pairs<number, number>,
    ctx: CanvasRenderingContext2D
  ) => {
    ctx.beginPath();
    ctx.moveTo(points[0][0], points[0][1]);
    for (const p of points) {
      ctx.lineTo(p[0], p[1]);
    }
    ctx.stroke();
  };

  useEffect(() => {
    if (data && data.worksheetAttempt?.drawing) {
      history.current = data.worksheetAttempt.drawing;
      const ctx = getContext(null, null);
      if (ctx) {
        clearCanvas(ctx);
        drawCanvas(ctx);
      }
      render();
    }

    if (data && !data.worksheetAttempt?.drawing) {
      const ctx = getContext(null, null);
      if (ctx) {
        history.current = [];
        clearCanvas(ctx);
      }
    }

    if (data && data.worksheetAttempt?.textboxes) {
      setTextInputs(
        (data.worksheetAttempt.textboxes as Textbox[]).map((input: Textbox) => {
          return {
            id: uuidv4(),
            x: input.position.x,
            y: input.position.y,
            text: input.text,
            fontSize: input.fontSize || drawingSettings.current.fontSize,
          };
        })
      );
    } else {
      setTextInputs([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, drawingSettings]);

  useEffect(() => {
    document.addEventListener("pointerup", onPointerUp);
    document.addEventListener("touchend", onTouchEnd);
    document.addEventListener("pointermove", onPointerMove);
    document.addEventListener("touchmove", onTouchMove);
    const ctx = getContext(null, null);
    ctx?.setTransform(1, 0, 0, 1, -CANVAS_WIDTH / 2, -(PAN_LIMIT - height) / 2);
    const newCtx = getContext(null, null);
    if (newCtx) {
      drawCanvas(newCtx);
    }
    return () => {
      document.removeEventListener("pointerup", onPointerUp);
      document.removeEventListener("touchend", onTouchEnd);
      document.removeEventListener("pointermove", onPointerMove);
      document.removeEventListener("touchmove", onTouchMove);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height, textInputs, setTextInputs]);

  return (
    <>
      {mutation.isPending ? (
        <div className="absolute left-0 w-6 z-50 ml-auto mr-auto right-0 top-4">
          <Spinner />{" "}
        </div>
      ) : null}
      <div className="w-full m-auto p-6 fixed bottom-3 right-0 z-50">
        {isPending ? (
          "Loading worksheet..."
        ) : (
          <Button
            size="lg"
            className="bg-green-800 w-full"
            onClick={saveButtonClickHandler}
          >
            {rest.worksheet.isLastWorksheet
              ? "Save and Return Home"
              : "Save and Start Next >"}
          </Button>
        )}
      </div>

      <div className="relative">
        {isPending && (
          <div className="w-full h-screen">
            <Spinner />
          </div>
        )}
        {error && <div>{error.message}</div>}
        <div className="relative">
          <canvas
            ref={canvas}
            width={CANVAS_WIDTH}
            height={height}
            onPointerDown={onPointerDown}
            onTouchStart={onTouchStart}
            className={cn(
              drawingSettings.current!.mode === MODES.PAN
                ? "moving"
                : "drawing",
              "z-10 touch-none relative margin-auto"
            )}
          />
        </div>
        <CanvasToolbar
          drawingSettings={drawingSettings}
          setMode={setMode}
          changeColor={changeColor}
          undoCanvas={undoCanvas}
          isLoading={isPending}
          redoCanvas={redoCanvas}
          historyLength={history?.current?.length}
          redoHistoryLength={redoHistory?.current?.length}
        />
        <div
          className="menu right z-20"
          onPointerDown={(e) => e.stopPropagation()}
          onTouchStart={(e) => e.stopPropagation()}
          onPointerUp={(e) => e.stopPropagation()}
          onTouchEnd={(e) => e.stopPropagation()}
          aria-disabled={drawing}
        >
          <button
            className="button"
            type="button"
            onClick={saveCanvasHandler}
            disabled={history?.current?.length === 0 && textInputs.length === 0}
          >
            {isPending ? (
              <Skeleton className="w-5 aspect-square" />
            ) : (
              <img src={exportIcon} alt="export" title="export" />
            )}
          </button>
          <input
            ref={importInput}
            className="hidden"
            type="file"
            accept="application/json"
            onChange={importCanvas}
          />
          <button
            className="button"
            type="button"
            onClick={() => {
              navigate({
                to: "/student/set/$setId/book/$bookId/worksheet/$worksheetId",
                params: {
                  setId: rest.setId,
                  bookId: rest.bookId,
                  worksheetId: (
                    Number(rest.worksheet.currentWorksheetId) + 1
                  ).toString(),
                },
              });
            }}
          >
            <ArrowBigRightDash />
          </button>
        </div>
        <div className="z-30 absolute top-0">
          {textInputs.map((input) => (
            <EditableText
              mode={drawingSettings.current.mode}
              key={input.id}
              id={input.id}
              position={{ x: input.x, y: input.y }}
              text={input.text}
              setText={updateText}
              remove={removeText}
              onDrag={updateDragPosition}
              fontSize={input.fontSize ?? drawingSettings.current?.fontSize}
              changeFontSize={(id, delta) => {
                setTextInputs((inputs) =>
                  inputs.map((i) => {
                    if (i.id === id) {
                      const newFontSize = Math.max(
                        8,
                        (i.fontSize || 12) + delta
                      );
                      changeDrawingFontSize(newFontSize); // Make new text using latest font size used
                      return { ...i, fontSize: newFontSize };
                    }
                    return i;
                  })
                );
                render();
              }}
              saveCanvas={saveCanvasHandler}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Canvas;
