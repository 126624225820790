import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createRouter } from "@tanstack/react-router";

// Import the generated route tree
import { routeTree } from "./routeTree.gen";
import { App } from "./App";
import { AuthProvider } from "./auth";
import Hotjar from "./components/hotjar";

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
    },
  },
});

// Create a new router instance
const router = createRouter({
  routeTree,
  context: {
    queryClient,
    auth: undefined!,
  },
});

export type Router = typeof router;

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: Router;
  }
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <App router={router} />
        <Hotjar />
      </AuthProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
