import { User } from "@server/shared-types";
import { createContext, useContext } from "react";

export interface AuthContext {
  isAuthenticated: boolean;
  logout: () => Promise<void>;
  getMeAndSetUser: () => Promise<void>;
  user: User | null;
}

export const AuthContext = createContext<AuthContext | null>(null);

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
