import { Button } from "@/components/ui/button";
import {
  Card,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Link, createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth/operations/")({
  component: () => <OperationsPage />,
});

function OperationsPage() {
  return (
    <div className="flex flex-col sm:gap-4 sm:py-4 sm:pl-14 ">
      <main className="h-screen">
        <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-4">
          <Card className="sm:col-span-2" x-chunk="dashboard-05-chunk-0">
            <CardHeader className="pb-3">
              <CardTitle>Learning Units</CardTitle>
              <CardDescription className="max-w-lg text-balance leading-relaxed">
                View all Learning Units and Subunits based on Edukita's
                levelling system
              </CardDescription>
            </CardHeader>
            <CardFooter>
              <Button>
                <Link to="/operations/units">View Units</Link>
              </Button>
            </CardFooter>
          </Card>
          <Card className="sm:col-span-2" x-chunk="dashboard-05-chunk-0">
            <CardHeader className="pb-3">
              <CardTitle>Worksheet Sets</CardTitle>
              <CardDescription className="max-w-lg text-balance leading-relaxed">
                View all worksheet sets
              </CardDescription>
            </CardHeader>
            <CardFooter>
              <Button>
                <Link to="/operations/worksheet-sets">View Worksheet Sets</Link>
              </Button>
            </CardFooter>
          </Card>
          <Card className="sm:col-span-2" x-chunk="dashboard-05-chunk-0">
            <CardHeader className="pb-3">
              <CardTitle>Assignments</CardTitle>
              <CardDescription className="max-w-lg text-balance leading-relaxed">
                View all student worksheet set assignments
              </CardDescription>
            </CardHeader>
            <CardFooter>
              <Button>
                <Link to="/operations/assignments">View Assignments</Link>
              </Button>
            </CardFooter>
          </Card>
        </div>
      </main>
    </div>
  );
}
