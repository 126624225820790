import { Spinner } from "@/components/ui/spinner";
import { useAuth } from "@/lib/useAuth";
import { createFileRoute, redirect } from "@tanstack/react-router";
import { useEffect } from "react";

export const Route = createFileRoute("/_auth/home")({
  beforeLoad: ({ context }) => {
    if (!context.auth.isAuthenticated) {
      console.log("redirecting to /login");
      throw redirect({
        to: "/login",
        search: {
          redirect: "/home",
        },
      });
    }

    if (context.auth.user?.ops) {
      console.log("redirecting to /operations");
      throw redirect({
        to: "/operations",
      });
    }
    if (context.auth.user?.teacher) {
      console.log("redirecting to /teacher");
      throw redirect({
        to: "/teacher",
      });
    }
    console.log("redirecting to /student");
    throw redirect({
      to: "/student",
    });
  },
  component: () => <Home />,
});

export function Home() {
  const auth = useAuth();

  useEffect(() => {
    console.log("checking auth");
    console.log(auth.isAuthenticated);
    if (!auth.isAuthenticated) {
      throw redirect({
        to: "/login",
      });
    }
  }, [auth.isAuthenticated]);

  return <Spinner />;
}
